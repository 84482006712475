import { useRef } from 'react';

function FilterItem({ setFiltersParams, filterName, filterStatus, filter }) {
  const filterItemRef = useRef();

  return (
    <li
      className={`employees__filter-option employees__filter-option--draft' + ${
        filterStatus && 'employees__filter-option--active'
      }`}
      onClick={() =>
        setFiltersParams((prevState) => {
          const filterCurr = filterItemRef.current.dataset.filter;
          const filterNameCurr = filterItemRef.current.dataset.filtername;
          const newState = { ...prevState };
          newState[filterCurr][filterNameCurr] = !prevState[filterCurr][filterNameCurr];
          return newState;
        })
      }
      data-filter={filter}
      data-filtername={filterName}
      ref={filterItemRef}
    >
      <div className="employees__filter-box">
        <svg
          className="employees__filter-check"
          width="10"
          height="8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 4.5l2.124 2.124L8.97 1.28"
            stroke="#FFF"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          />
        </svg>
      </div>
      <h4>{filterName}</h4>
    </li>
  );
}

export default FilterItem;
