// import axiosInstance from './http';
import axios from 'axios';

export const getProjects = async () => {
  try {
    const response = await axios.get('https://fakestoreapi.com/products/');
    console.log(response);
    return response.data;
  } catch (err) {
    console.error(`${err.message}`);
    console.log('hello');
  }
};
