import _ from 'lodash';
import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { login, logout } from 'services/auth.js';
import { changeUserStats, resetPassword } from 'services/changeUser';
import { getProfileImage, getProfileId, getProfileImageId } from 'services/getUser';
import { changeProfilePhoto, changeProfileName } from 'services/profile';
import { uploadFile, deleteFile } from 'services/uploadFile';

const AuthContext = createContext({
  currentUser: {},
  isUserLoggedIn: false,
  handleLogin: () => {},
  handleLogout: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserLoggedIn, setUserLoggedIn] = useState(false);
  const navigate = useNavigate();

  console.log(currentUser);

  const loginUser = async (data) => {
    localStorage.setItem('user', JSON.stringify(data));
    const userObj = {};
    console.log('DATAAAA', data);
    userObj.imagePathURL = data.picture;
    userObj.username = data.name;
    userObj.role = data.roleId;
    userObj.email = data.email;

    setUserLoggedIn(true);
    setCurrentUser(userObj);
    navigate('/all-employees');
  };

  const handleLogin = async () => {
    const user = await login();
    console.log('AUTH CONTENXT::', user, typeof user === 'object' && user !== null);
    if (typeof user === 'object' && user !== null) {
      loginUser(user);
    }
  };

  const handleLogout = () => {
    console.log('User LOGGED OUT!!');
    setCurrentUser(null);
    setUserLoggedIn(false);
    logout();
    localStorage.clear();
    navigate('/');
  };

  const changeUsername = async (userId, newUsername, isChaningCurrentUser = true) => {
    const newUserData = await changeUserStats(userId, newUsername);
    const profileId = await getProfileId(userId);
    await changeProfileName(profileId, newUsername);
    if (!isChaningCurrentUser) return;
    const user = _.cloneDeep(currentUser);
    user['username'] = newUserData['username'];
    setCurrentUser(user);
  };

  const changeUserPhoto = async (uploadFileData, userId) => {
    const [uploadResponse, profileId] = await Promise.all([
      uploadFile(uploadFileData),
      getProfileId(userId),
    ]);
    const oldProfilePhotoId = await getProfileImageId(userId);
    deleteFile(oldProfilePhotoId);

    await changeProfilePhoto(profileId, uploadResponse[0].id);
    const userImage = await getProfileImage(userId);
    const user = _.cloneDeep(currentUser);
    user['imagePathURL'] = userImage[0];
    user['imageName'] = userImage[1];
    setCurrentUser(user);
  };

  const changePassword = async (userId, userNewPassword) => {
    const resetPasswordResponse = await resetPassword(userId, userNewPassword);
    console.log('resetPasswordResponse', resetPasswordResponse, userNewPassword);
  };

  const isCurrentPasswordCorrect = async (userCurrentPassword, userEmail) => {
    const loginResponse = await login(userEmail, userCurrentPassword);
    if (loginResponse.status === 200) {
      return true;
    }
    return false;
  };

  //we probably dont need jwt cuz we are doing authentication with google oauth
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) {
      loginUser(currentUser);
    }
  }, []);

  const authContext = {
    currentUser,
    isUserLoggedIn,
    handleLogin,
    handleLogout,
    changeUsername,
    changeUserPhoto,
    changePassword,
    isCurrentPasswordCorrect,
  };

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

export default AuthContext;
