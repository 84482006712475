/* eslint-disable no-unused-vars */

import { Flex, Stack, Text, Button, Grid, Textarea, Input, FormControl } from '@chakra-ui/react';
import moment from 'moment';
import { Paginator } from 'primereact/paginator';
import { useState } from 'react';

// const paginatorCss = css({
//   '.p-paginator-page, .p-paginator-icon': {
//     fontSize: '18px',
//   },
//   '.p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last': {
//     width: '0px',
//     padding: '16px',
//     backgroundColor: '#000',
//   },
//   '@media (max-width: 500px)': {
//     '.p-paginator-page, .p-paginator-icon': {
//       fontSize: '15px',
//     },
//   },
//   '@media (max-width: 450px)': {
//     '.p-paginator-page, .p-paginator-icon': {
//       fontSize: '14px',
//       minWidth: '24px',
//     },
//     '.p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last': {
//       padding: '12px',
//     },
//   },
// });

const EmployeeNotes = ({ result, setTrigger, employeeId }) => {
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(4);
  const [notes, setNotes] = useState(result.notes);
  const [paginatedNotes, setPaginatedNotes] = useState(notes.slice(0, basicRows));

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setPaginatedNotes(notes.slice(event.first, event.first + event.rows));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const note = { subject, text, employeeId: employeeId };
    console.log(note);

    fetch('http://localhost:5001/note/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(note),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setTrigger((curr) => !curr);
        setText('');
        setSubject('');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const deleteNote = (noteUid) => {
    fetch(`http://localhost:5001/note/${noteUid}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setTrigger((curr) => !curr);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  return (
    <div className="employee-notes">
      <Stack py="10px">
        <Flex mb="8px" wrap={{ base: 'wrap' }}>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <Input
                mb="3px"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                maxLength="50"
                fontSize="14px"
              ></Input>
            </FormControl>
            <FormControl>
              <Textarea
                mb="3px"
                value={text}
                onChange={(e) => setText(e.target.value)}
                rows="5"
                maxLength="300"
                fontSize="12px"
              ></Textarea>
            </FormControl>
            <Button ml="5px" type="submit">
              Add new note
            </Button>
          </form>
        </Flex>
        <Grid gap="1rem" gridTemplateColumns="repeat(auto-fit, minmax(227px, 1fr))" overflow="auto">
          {paginatedNotes.map((note) => {
            return (
              <Text
                key={note.id}
                border="0.5px solid rgba(32,192,34,0.16)"
                borderRadius="5px"
                // width="300px"
                padding="10px"
                mb={{ base: '20px', md: '10px' }}
                position="relative"
                backgroundColor="#fefbc4"
                height="120px"
              >
                <Text fontWeight="bold" fontSize="15px">
                  {note.subject}
                </Text>
                <Text
                  fontStyle="italic"
                  fontSize="15px"
                  paddingBottom="30px"
                  height="50px"
                  overflow="auto"
                >
                  {note.text}
                </Text>
                <Flex justifyContent="space-between" fontSize="13px">
                  <Text>{note.projectManagerId}</Text>

                  <Text>{moment(note.createdAt).utc().format('MMMM Do YYYY, h:mm:ss a')}</Text>
                </Flex>
                <Button
                  position="absolute"
                  top="3px"
                  right="3px"
                  onClick={() => deleteNote(note.uid)}
                >
                  X
                </Button>
              </Text>
            );
          })}
        </Grid>
        <Paginator
          first={basicFirst}
          rows={basicRows}
          totalRecords={notes.length}
          rowsPerPageOptions={false}
          onPageChange={onBasicPageChange}
          // css={paginatorCss}
        ></Paginator>
      </Stack>
    </div>
  );
};

export default EmployeeNotes;
