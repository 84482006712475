import axiosInstance from './http';

export const login = async () => {
  try {
    const response = await axiosInstance.get('/auth/user', {
      withCredentials: true,
    });
    console.log(response.data, '🚀🤘USER, res:::', response);
    return response.data;
  } catch (err) {
    console.error(`${err.message}, 💥🤯 LOOOOOOO`, err.response);
    return null;
  }
};

export const logout = async () => {
  try {
    const response = await axiosInstance.get('/logout', { withCredentials: true });
    console.log(response, '🚀🤘USER LOGOUT');
    return response;
  } catch (err) {
    console.error(`${err.message}, 💥🤯`);
    return err.response;
  }
};
