/* eslint-disable no-unused-vars */
import { Text, Button, Stack, Flex } from '@chakra-ui/react';

const EmployeeProjects = ({ result }) => {
  return (
    <div className="employee-projects">
      <Stack padding="30px">
        {!result.project ? (
          <div>This user currently is not working on any project.</div>
        ) : (
          <div>Currently working on: {result?.project?.project}</div>
        )}

        <Flex
          padding="5px"
          borderRadius="5px"
          justifyContent="space-between"
          backgroundColor="#f4e0fa"
        >
          <Text>
            <p>{result.projectId} Project 1</p>
          </Text>
          <Button>x</Button>
        </Flex>
        <Flex justifyContent="center">
          <Button disabled="true">Add employee to your project</Button>
        </Flex>
      </Stack>
    </div>
  );
};

export default EmployeeProjects;
