/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/** @jsxImportSource @emotion/react */
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Tfoot,
  Box,
  Input,
  Tooltip,
  Button,
  Spacer,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/vela-purple/theme.css';
import 'primereact/resources/primereact.css';
import { css } from '@emotion/react';
import { Paginator } from 'primereact/paginator';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import EmployeeOverview from '../EmployeeOverview/EmployeeOverview.jsx';

import Filters from './Filters.jsx';

import LoadingSpinner from 'components/features/LoadingSpinner/LoadingSpinner';
import ModalBox from 'components/features/Modal/ModalBox.jsx';
import { fetchEmployees } from 'services/employees';

/* import fakeEmployeeData from './fakeData.js' */

// eslint-disable-next-line no-unused-vars

const paginatorCss = css({
  '.p-paginator-page, .p-paginator-icon': {
    fontSize: '18px',
  },
  '.p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last': {
    width: '0px',
    padding: '16px',
  },
  '@media (max-width: 500px)': {
    '.p-paginator-page, .p-paginator-icon': {
      fontSize: '15px',
    },
  },
  '@media (max-width: 450px)': {
    '.p-paginator-page, .p-paginator-icon': {
      fontSize: '14px',
      minWidth: '24px',
    },
    '.p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last': {
      padding: '12px',
    },
  },
});

const divWidthCss = css({
  width: '90%',
  maxWidth: '1000px',
  margin: 'auto',
  '@media (max-width: 420px)': {
    padding: '10px',
    width: '98%',
  },
});

/* const fetchEmployeesFakeData = async () => {
  const lol = await new Promise((resolve) =>
    setTimeout(() => {
      return resolve(fakeEmployeeData);
    }, 2000)
  );
  return lol;
}; */

const thCss = css({
  '& th': {
    color: '#7C5DFA',
    fontSize: '12px',
  },
});

/// maybe concat strings and add ... and tooltip on hover to show the full text???

function AllEmployees({ employeesType, mainContentRef }) {
  // When u get stuck logged in :S
  //return <p>lol</p>;
  const [employees, setEmployees] = useState([]);
  const [employeesFiltered, setEmployeesFiltered] = useState([]);
  const [employeesFilteredAndSearched, setEmployeesFilteredAndSearched] = useState([]);
  const [employeesToShowOnTable, setEmployeesToShowOnTable] = useState([]);
  const [mainContentWidthSize, setMainContentWidthSize] = useState(1051);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(100);
  const [searchQuery, setSearchQuery] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [employeeId, setEmployeeId] = useState('');

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setEmployeesToShowOnTable(employeesFiltered.slice(event.first, event.first + event.rows));
  };

  const fetchEmployeesAsync = async () => {
    const employeesData = await fetchEmployees();
    setEmployees(employeesData);
    setEmployeesFiltered(employeesData);
    setEmployeesFilteredAndSearched(employeesData);
    setEmployeesToShowOnTable(employeesData.slice(0, basicRows));
  };

  useEffect(() => {
    const filtEmployees = employeesFiltered.filter((employee) => {
      if (searchQuery.length === 0) return true;
      return (employee.name.toLowerCase() + ' ' + employee.lastName.toLowerCase()).includes(
        searchQuery
      );
    });
    setEmployeesFilteredAndSearched(filtEmployees);
  }, [searchQuery, employeesFiltered]);

  useEffect(() => {
    setEmployeesToShowOnTable(employeesFilteredAndSearched.slice(0, basicRows));
  }, [employeesFilteredAndSearched, basicRows]);

  useEffect(() => {
    // this is for demo in reality window size people dont change lol
    const onWindowResize = () => {
      var computedStyle = getComputedStyle(mainContentRef?.current);
      let mainContentTrueWidth = mainContentRef?.current.clientWidth; // width with padding
      mainContentTrueWidth -=
        parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
      [1051, 951, 851, 651, 0].some((widthLimit) => {
        if (mainContentTrueWidth > widthLimit) {
          setMainContentWidthSize(widthLimit);
          return true;
        }
      });
    };
    window.addEventListener('resize', onWindowResize);

    let computedStyle = getComputedStyle(mainContentRef?.current);
    let mainContentTrueWidth = mainContentRef?.current.clientWidth; // width with padding
    mainContentTrueWidth -=
      parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    [1051, 951, 851, 651, 0].some((widthLimit) => {
      if (mainContentTrueWidth > widthLimit) {
        setMainContentWidthSize(widthLimit);
        return true;
      }
    });

    fetchEmployeesAsync();

    // ...
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  const filterFunction = (event) => {
    setSearchQuery(event.target.value.trim().toLowerCase());
  };

  return (
    <>
      <h1
        css={css`
          ${divWidthCss};
          font-size: 18px;
        `}
      >
        {employeesType.toUpperCase() + ' employees'.toUpperCase()}
      </h1>
      {employees.length ? (
        <Box css={divWidthCss}>
          <Filters employeesData={employees} setEmployeesFiltered={setEmployeesFiltered} />
          <HStack>
            <Input
              width={'300px'}
              placeholder={'Find Employee'}
              onChange={filterFunction}
              height={'30px'}
              borderColor="none"
              border={'1px solid #435354'}
              focusBorderColor={'1px solid #435354'}
            />
            <ModalBox isOpen={isOpen} onClose={onClose}>
              <EmployeeOverview employeeId={employeeId} />
            </ModalBox>
            {/* <Button onClick={onOpen}>Modal</Button> */}
            <Spacer></Spacer>
          </HStack>
          <Table
            variant="simple"
            size="lg"
            css={[
              css({
                marginTop: '10px',
                fontSize: '20px',
                '@media (max-width: 1000px)': {
                  fontSize: '18px',
                },
                '@media (max-width: 420px)': {
                  fontSize: '14px',
                },
              }),
              thCss,
            ]}
          >
            <TableCaption>Employees</TableCaption>
            <Thead>
              <Tr>
                <Th>Employee</Th>
                {mainContentWidthSize > 650 && <Th>Technology</Th>}
                {mainContentWidthSize > 850 && <Th>Seniority</Th>}
                {mainContentWidthSize > 950 && <Th> City </Th>}
                {mainContentWidthSize > 1050 && <Th> From </Th>}
                <Th>Project</Th>
                {mainContentWidthSize > 750 && <Th>Project Manager</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {employeesToShowOnTable.map((employee, index) => {
                const employeeTechonologiesString = employee.technologies
                  .map((tech) => tech.technology)
                  .join('/');
                return (
                  // za sada je key index posle stavi employee.id
                  <Tr
                    key={index}
                    css={css`
                      padding: 0 10px;
                      cursor: pointer;
                      &:hover {
                        color: #7c5dfa;
                      }
                    `}
                    onClick={() => {
                      setEmployeeId(employee.email.substring(0, employee.email.lastIndexOf('@')));
                      onOpen();
                    }}
                  >
                    <Td>{employee.name + ' ' + employee.lastName}</Td>
                    {mainContentWidthSize > 650 && (
                      <Td>
                        {employeeTechonologiesString.length > 20 ? (
                          <Tooltip
                            fontSize={'22px'}
                            label={employeeTechonologiesString}
                            aria-label="A tooltip"
                          >
                            {employeeTechonologiesString.slice(0, 17) + '...'}
                          </Tooltip>
                        ) : (
                          employeeTechonologiesString
                        )}
                      </Td>
                    )}
                    {mainContentWidthSize > 850 && <Td>{employee.seniorityId}</Td>}
                    {mainContentWidthSize > 950 && <Td>{employee.cityId}</Td>}
                    {mainContentWidthSize > 1050 && <Td>{employee.countryId}</Td>}
                    <Td>{employee.project?.project}</Td>
                    {mainContentWidthSize > 750 && (
                      <Td>
                        {employee.project?.projectManager?.length > 20 ? (
                          <Tooltip
                            fontSize={'22px'}
                            label={employee.project?.projectManager}
                            aria-label="A tooltip"
                          >
                            {employee.project?.projectManager?.slice(0, 17) + '...'}
                          </Tooltip>
                        ) : (
                          employee.project?.projectManager
                        )}
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Employee</Th>
                {mainContentWidthSize > 650 && <Th>Technology</Th>}
                {mainContentWidthSize > 850 && <Th>Seniority</Th>}
                {mainContentWidthSize > 950 && <Th> City </Th>}
                {mainContentWidthSize > 1050 && <Th> From </Th>}
                <Th>Project</Th>
                {mainContentWidthSize > 750 && <Th>Project Manager</Th>}
              </Tr>
            </Tfoot>
          </Table>
          <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={employeesFiltered.length}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onBasicPageChange}
            css={paginatorCss}
          ></Paginator>
        </Box>
      ) : (
        <LoadingSpinner
          css={css`
            border: 4px solid green;
          `}
        />
      )}
    </>
  );
}

export default AllEmployees;
