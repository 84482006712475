import { Text, Heading, Flex, Image, Center } from '@chakra-ui/react';

const EmployeeInfo = ({ result }) => {
  return (
    <div className="employee-info">
      <Center mb="10px">
        <Image
          src={`https://ui-avatars.com/api/?name=${result?.name}+${result?.lastName}&rounded=true&background=random`}
        />
      </Center>
      <Heading mb="10px">
        {result.name} {result.lastName}
      </Heading>
      <Flex justifyContent="center">
        <Text mr="2px">{result.cityId}, </Text>
        <Text>{result.countryId}</Text>
      </Flex>
      <Flex justifyContent="center">
        <Text fontWeight="bold" mr="2px">
          Seniority:
        </Text>
        <Text>{result.seniorityId}</Text>
      </Flex>
      <Text fontWeight="semibold">
        Technologies:
        {result.technologies.map((technology) => {
          return (
            <span className="employee-technology" key={technology.slug}>
              {technology.technology}
            </span>
          );
        })}
      </Text>
    </div>
  );
};

export default EmployeeInfo;
