/* eslint-disable no-unused-vars */
import { Tab, Tabs, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import './EmployeeOverview.css';
import axios from 'axios';
import { useEffect, useState } from 'react';

import EmployeeInfo from './EmployeeInfo';
import EmployeeNotes from './EmployeeNotes';
import EmployeeProjects from './EmployeeProjects';

const EmployeeOverview = ({ employeeId }) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    console.log('Test');

    const fetchData = async () => {
      try {
        const response = await axios.get(`http://localhost:5001/employee/${employeeId}`);
        console.log(response);
        console.log(response.data);
        setResult(response.data);
      } catch (err) {
        console.error(`${err.message}`);
        console.log('hello');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [trigger, employeeId]);
  return (
    <div className="employee-overview">
      {loading ? (
        'Loading'
      ) : (
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList>
            <Tab fontSize="15px">Info</Tab>
            <Tab fontSize="15px">Projects</Tab>
            <Tab fontSize="15px">Notes</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <EmployeeInfo result={result} />
            </TabPanel>
            <TabPanel>
              <EmployeeProjects result={result} />
            </TabPanel>
            <TabPanel>
              <EmployeeNotes result={result} setTrigger={setTrigger} employeeId={employeeId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </div>
  );
};

export default EmployeeOverview;
