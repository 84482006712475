/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useMemo, useEffect } from 'react';

import fakeData from './fakeData';
import Filter from './Filter';

// this should get from route like localhost:5000/cities but for now we will use propdata
// or we can iterate over all employees data and extract all these filters.
const getCities = () => {
  return { bg: true, 'Novi Sad': true };
};
const getCountries = () => {
  return { Srb: true, Bosnia: true, Montenegro: true, Ukraine: true };
};
const getTechnologies = () => {
  return { 'Front-end': true, 'Back-End': true, Devops: true };
};
const getSeniorities = () => {
  return { Junior: true, Medior: true, Senior: true };
};
const getProjects = () => {
  return { EmployeeInfo: true, QuantoxWorkMain: true };
};
const getProjectManagers = () => {
  return { Petar: true, Ivo: true };
};

function setValueToFilterObj(obj, key, employeeVal) {
  if (obj[key]) {
    obj[key][employeeVal] = false;
  } else {
    obj[key] = {};
    obj[key][employeeVal] = false;
  }
}

const getAvailableFilters = (employeesData) => {
  const obj = {};
  employeesData.forEach((employee) => {
    //each employee should have only one project manager
    /* setValueToFilterObj(
      obj,
      'project-Manager',
      employee.projects[0].Employee_Project.EmployeeEmail
    ); */

    for (let key in employee) {
      let employeeVal = employee[key];
      if (key == 'technologies') {
        employee[key].forEach((techObj) => {
          employeeVal = techObj.technology;
          setValueToFilterObj(obj, key, employeeVal);
        });
      } else if (key == 'project') {
        employeeVal = employee.project?.projectManager;
        if (employeeVal) {
          setValueToFilterObj(obj, 'project-Manager', employeeVal);
        }
      } else {
        setValueToFilterObj(obj, key, employeeVal);
      }
    }
  });
  console.log('returend obj??', obj);
  delete obj.name;
  delete obj.lastName;
  delete obj.createdAt;
  delete obj.updatedAt;
  delete obj.notes;
  delete obj.email;
  return obj;
};

const filtersCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .employees__filter {
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
    height: 60px;
    cursor: pointer;
    position: relative;
    font-size: 16px;
  }

  .employees__filter--active .employees__filter-menu {
    display: flex;
  }

  .employees__filter svg {
    margin-left: 20px;
  }

  .employees__filter--active > svg {
    transform: rotate(180deg);
  }

  .employees__filter-option {
    display: flex;
    padding: 10px;
  }

  .employees__filter-option:hover .employees__filter-box {
    border: 1px solid var(--primary-color);
  }

  .employees__filter-box {
    min-width: 20px;
    height: 20px;
    background-color: var(--surface-border);
    border: 1px solid transparent;
    border-radius: 2px;
    margin-right: 10px;
    position: relative;
  }

  .employees__filter-option--active .employees__filter-box {
    background-color: var(--primary-color);
  }

  .employees__filter-check {
    display: none;
    position: absolute;
    top: 50%;
    left: -50%;
    transform: translate(-50%, -50%);
  }

  .employees__filter-option--active .employees__filter-check {
    display: block;
  }

  .employees__filter-menu h4 {
    line-height: 20px;
  }

  @media (max-width: 500px) {
    .employees__filter {
      height: 45px;
    }
  }
`;

function Filters({ employeesData, setEmployeesFiltered }) {
  const [filtersParams, setFiltersParams] = useState(() => {
    const initialState = getAvailableFilters(employeesData);
    return initialState;
  });

  useEffect(() => {
    for (let filter in filtersParams) {
      const filterValuesObject = filtersParams[filter];
      const newEmployeesDataAfterFilter = [];
      let wentThroughFilter = false;
      for (let innerFilter in filterValuesObject) {
        const filterValue = filterValuesObject[innerFilter];

        if (filterValue) {
          wentThroughFilter = true;
          employeesData.forEach((employee) => {
            /* if (filter === 'project-Manager') {
              if (employee.projects[0].Employee_Project.EmployeeEmail === innerFilter) {
                newEmployeesDataAfterFilter.push(employee);
              }
            } else  */ if (filter === 'technologies') {
              employee[filter].forEach((techObj) => {
                if (techObj.technology === innerFilter) {
                  newEmployeesDataAfterFilter.push(employee);
                }
              });
            } else if (filter === 'projects') {
              employee[filter].forEach((projObj) => {
                if (projObj.project === innerFilter) {
                  newEmployeesDataAfterFilter.push(employee);
                }
              });
            } else {
              if (employee[filter] == innerFilter) {
                newEmployeesDataAfterFilter.push(employee);
              }
            }
          });
        }
      }
      if (wentThroughFilter) employeesData = newEmployeesDataAfterFilter;
    }
    setEmployeesFiltered(employeesData);
  }, [filtersParams]);

  const filtersArr = useMemo(
    () => Object.keys(filtersParams).map((key) => [key, filtersParams[key]]),
    [filtersParams]
  );

  return (
    <div css={filtersCss}>
      {filtersArr.map(([filterName, filterParams]) => (
        <Filter
          key={filterName}
          setFiltersParams={setFiltersParams}
          filterName={filterName}
          parameters={filterParams}
        />
      ))}
    </div>
  );
}

export default Filters;
