import { VStack, Heading, Menu, Flex, Divider, Image, Link, Text, Center } from '@chakra-ui/react';
import React from 'react';
import { Link as ReachLink } from 'react-router-dom';

import MenuItems from './MenuItems.jsx';

import { useAuthContext } from 'context/AuthContext.jsx';

function MenuContent({ onClose }) {
  // eslint-disable-next-line no-unused-vars
  const { isUserLoggedIn, currentUser } = useAuthContext();

  console.log(currentUser);

  // if (!isUserLoggedIn) {
  //   return <div />;
  // }

  return (
    <React.Fragment>
      <VStack
        pos="fixed"
        top="0"
        left="0"
        p="2rem"
        pt="4rem"
        bg="color.background"
        color="color.whiteText"
        height="100%"
        width="300px"
        zIndex="1"
        // bgGradient="linear(color.background 50%, color.backgroundLight 100%)"
      >
        <Flex justify="center" align="center" direction="column">
          <Link as={ReachLink} to="#" textDecoration="none">
            <Image
              src={currentUser?.imagePathURL}
              alt="currentUser"
              boxSize="100px"
              borderRadius="full"
              mb="15px"
            ></Image>
          </Link>
          <Link
            to="#"
            as={ReachLink}
            mb="15px"
            _hover={{
              textDecoration: 'none',
            }}
          >
            <Heading fontSize="x-large">{currentUser?.username}</Heading>
          </Link>
          <Text fontSize="initial" paddingBottom="20px">
            PROJECT MANAGER
          </Text>
        </Flex>

        <Center marginBottom="15px" w="100%">
          <Divider
            orientation="horizontal"
            w="75%"
            borderBottomWidth="2px"
            borderBottomColor="#0e7c2b"
          />
        </Center>

        <Menu>
          <MenuItems onClose={onClose} menuType={'desktop'} />
        </Menu>
      </VStack>
    </React.Fragment>
  );
}

export default MenuContent;
