import { Icon, Text } from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { AiOutlineTeam } from 'react-icons/ai';
import { MdOutlinePending } from 'react-icons/md';
import { RiQuestionnaireLine, RiProfileLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';

import MenuLink from './MenuLink';
import MenuWrap from './MenuWrap';

function MenuItems({ onClose, menuType }) {
  const itemsRef = useRef([]);
  const [activeLink, setActiveLink] = useState(0);
  let menuItems;
  const location = useLocation();

  useEffect(() => {
    // runs on location, i.e. route, change
    menuItems.forEach((item, index) => {
      if (item.href === location.pathname) setActiveLink(index);
    });
  }, [location, menuItems]);

  switch (menuType) {
    default:
      menuItems = [
        {
          href: '/all-employees',
          icon: MdOutlinePending,
          text: 'All Employees',
        },
        {
          href: '/my-employees',
          icon: AiOutlineTeam,
          text: 'My Employees',
        },
        {
          href: '/projects',
          icon: RiQuestionnaireLine,
          text: 'Projects',
        },
        {
          href: '/my-profile',
          icon: RiProfileLine,
          text: 'My Profile',
        },
        {
          href: '/system-admin',
          icon: RiProfileLine,
          text: 'System Administrator',
        },
      ];
      break;
  }

  return (
    <MenuWrap menuType={menuType}>
      {menuItems.map(({ text, icon, href }, i) => (
        <Link to={href} key={i} onClick={() => onClose()}>
          <MenuLink
            menuType={menuType}
            innerRef={(el) => (itemsRef.current[i] = el)}
            active={i == activeLink ? true : false}
            marginBottom="10px"
          >
            <Text fontSize="2xl" padding="9px">
              <Icon as={icon} mr="1rem" mt=".2rem"></Icon>
              {text}
            </Text>
          </MenuLink>
        </Link>
      ))}
    </MenuWrap>
  );
}

export default MenuItems;
