// import axiosInstance from './http';
import axios from 'axios';

// u selectedValue je country, city, seniority
// u t je Srbija, Madjarska, junior, PHP...

const manageData = {
  changeData: async (selectedValue, stariItem, noviItem) => {
    try {
      const key = selectedValue;
      const m = stariItem.toLowerCase().replace(/ /g, '-');

      console.log(selectedValue, m, 'ffafafaafafafaffa', noviItem);
      console.log(key);
      const response = await axios.put(`https://api.employee.mom.rs/${selectedValue}/${m}`, {
        [key]: noviItem,
      });
      console.log(response);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
  deleteData: async (t, selectedValue) => {
    try {
      console.log(selectedValue, t);
      const m = t.toLowerCase().replace(/ /g, '-');
      console.log(m);
      const response = await axios.delete(`https://api.employee.mom.rs/${selectedValue}/${m}`);
      console.log(response);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
  addData: async (t, selectedValue) => {
    try {
      // const obj = {};
      const key = selectedValue;
      const response = await axios.post(`https://api.employee.mom.rs/${selectedValue}`, {
        [key]: t,
      });
      return response;
    } catch (err) {
      console.error(`${err.message}`);
    }
  },
  addCity: async (country, city) => {
    try {
      // const key = city;
      const response = await axios.post(`https://api.employee.mom.rs/city`, {
        country: country,
        city: city,
      });
      return response;
    } catch (err) {
      console.error(`${err.message}`);
    }
  },
  addEmployee: async (name, lastName, email) => {
    try {
      const response = await axios.post(`https://api.employee.mom.rs/employee`, {
        name: name,
        lastName: lastName,
        email: email,
      });
      return response;
    } catch (err) {
      console.error(`${err.message}`);
    }
  },
  deleteEmployee: async (email, selectedValue) => {
    try {
      // const mail = t.toLowerCase();
      const sliceEmail = email.slice(0, email.indexOf('@'));
      console.log(sliceEmail);
      const response = await axios.delete(`https://api.employee.mom.rs/${selectedValue}/yyyyyy`);
      console.log(response);
      console.log(sliceEmail);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
};

export default manageData;
