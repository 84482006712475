import axiosInstance from './http';

export const fetchEmployees = async () => {
  try {
    const response = await axiosInstance.get(`/employee`);
    console.log('fetch employees:: response : ', response);
    return response.data;
  } catch (error) {
    console.error(`${error.message}, 💥🤯`);
    return [
      {
        name: 'Violeta',
        lastName: 'Petrović',
        email: 'violeta.petrovic@quantox.com',
        seniorityId: 'Junior',
        countryId: 'Srbija',
        cityId: 'Niš',
        projects: [
          {
            project: 'Employee Info',
            slug: 'employee-info',
            Employee_Project: {
              ProjectProject: 'Employee Info',
              EmployeeEmail: 'violeta.petrovic@quantox.com',
            },
          },
        ],
        technologies: [
          {
            technology: 'Java Script',
            slug: 'java-script',
            Employee_Technology: {
              EmployeeEmail: 'violeta.petrovic@quantox.com',
              TechnologyTechnology: 'Java Script',
            },
          },
        ],
      },
    ];
  }
};

// eslint-disable-next-line no-unused-vars
export const addEmployee = async (employeeObj) => {
  console.log('employee Object', employeeObj);
  try {
    const response = await axiosInstance.post(`/employee`, {
      name: employeeObj.inputName,
      lastName: employeeObj.inputLastName,
      email: employeeObj.inputEmail,
      project: '',
      country: employeeObj.countrySelected,
      city: employeeObj.citySelected,
      seniority: employeeObj.senioritySelected,
      technologies: employeeObj.technologies,
    });
    console.log('add employee:: response : ', response);
    return response;
  } catch (error) {
    console.error(`${error.message}, 💥🤯`);
    return error;
  }
};

export const getEmployee = async (employeeEmailPrefix) => {
  console.log('employeeEmailPrefix: ', employeeEmailPrefix);
  try {
    const response = await axiosInstance.get(`/employee/${employeeEmailPrefix}`);
    console.log('get employee:: response : ', response);
    return response.data;
  } catch (error) {
    console.error(`${error.message}, 💥🤯`);
    return error;
  }
};

export const editEmployee = async (employeeObj, employeeEmailPrefix) => {
  console.log('editEmployee employeeEmailPrefix: ', employeeObj, employeeEmailPrefix);
  try {
    const response = await axiosInstance.put(`/employee/${employeeEmailPrefix}`, {
      name: employeeObj.inputName,
      lastName: employeeObj.inputLastName,
      email: employeeObj.inputEmail,
      project: '',
      country: employeeObj.countrySelected,
      city: employeeObj.citySelected,
      seniority: employeeObj.senioritySelected,
      technologies: employeeObj.technologies,
    });
    console.log('get employee:: response : ', response);
    return response;
  } catch (error) {
    console.error(`${error.message}, 💥🤯`);
    return error;
  }
};

export const fetchCountries = async () => {
  try {
    const response = await axiosInstance.get(`/country`);
    console.log('fetch country:: response : ', response);
    return response.data;
  } catch (error) {
    console.error(`${error.message}, 💥🤯`);
    return [];
  }
};

export const fetchTechnologies = async () => {
  try {
    const response = await axiosInstance.get(`/technology`);
    console.log('fetch fetchTechnologies:: response : ', response);
    return response.data;
  } catch (error) {
    console.error(`${error.message}, 💥🤯`);
    return [];
  }
};

export const fetchCities = async () => {
  try {
    const response = await axiosInstance.get(`/city`);
    console.log('fetch fetchCities:: response : ', response);
    return response.data;
  } catch (error) {
    console.error(`${error.message}, 💥🤯`);
    return [];
  }
};

export const fetchSeniorities = async () => {
  try {
    const response = await axiosInstance.get(`/seniority`);
    console.log('fetch fetchSeniorities:: response : ', response);
    return response.data;
  } catch (error) {
    console.error(`${error.message}, 💥🤯`);
    return [];
  }
};
