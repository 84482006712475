import { Box, Flex, Input, Text, Button, Tooltip, Divider } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import { getProjects } from 'services/fakeapi';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  async function getAllProjects() {
    const allProjects = await getProjects();
    const sortProjects = allProjects.sort(function (a, b) {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setProjects(sortProjects);
    setFilteredProjects(sortProjects);
    setIsLoading(false);
    console.log(sortProjects, 'fff');
  }

  console.log(projects, 'fsfsssfsfsfsfse');
  console.log(filteredProjects, 'filterrrrrrrrrrrrrrrrrrrrrrrrrr');

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    const filtProjects = projects.filter((project) => {
      console.log(project.title.toLowerCase().includes(searchQuery));
      return project.title.toLowerCase().includes(searchQuery);
    });
    setFilteredProjects(filtProjects);
  }, [searchQuery]);

  const filterFunction = (event) => {
    setSearchQuery(event.target.value.trim().toLowerCase());
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Box margin={'auto'} maxWidth={'1200px'}>
        <Box
          width="100%"
          top={{ base: '50px', md: '60px' }}
          position="fixed"
          left={{ base: 0, md: 'unset' }}
          background="#25323d"
          paddingTop={{ base: '15px', md: '40px' }}
          pl={{ base: '10px', md: '0px' }}
          zIndex="1"
        >
          <Text fontWeight="bold" textTransform="capitalize" color="#fff" fontSize="20px" pl="20px">
            Projects
          </Text>
          <Divider borderBottomWidth="1.5px" borderBottomColor="#0e7c2b" />
          <Flex justifyContent={'flex-start'} marginBottom={'30px'} m="15px">
            <Input
              width={'300px'}
              placeholder={'Find Project'}
              onChange={filterFunction}
              height={'30px'}
              borderColor="none"
              border={'1px solid #435354'}
              focusBorderColor={'1px solid #435354'}
            />
            <Button
              height="30px"
              bg="color.buttonGreen"
              color="#fff"
              fontSize="14px"
              ml="22px"
              minW="100px"
              _hover={{
                background: '#fff',
                color: '#000',
              }}
              _active={{
                background: '#fff',
                color: '#000',
              }}
            >
              My Projects
            </Button>
          </Flex>
        </Box>
        <Flex wrap="wrap" justifyContent="flex-start" marginTop="90px">
          {filteredProjects.map((project, id) => {
            return (
              <Flex
                key={id}
                borderRadius={'10px'}
                border={'1px solid #435354'}
                width="200px"
                m="15px"
                background="color.cardBg"
                p="11px"
              >
                <Box>
                  <Tooltip label={project.title} p="5px" fontSize="14px" maxW="200px">
                    <Text
                      fontSize={'16px'}
                      padding={'10px'}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      display="-webkit-box"
                      lineHeight="23px"
                      maxHeight="83px"
                      css={{
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {project.title}
                    </Text>
                  </Tooltip>
                </Box>
              </Flex>
            );
          })}
        </Flex>
      </Box>
    </>
  );
};

export default Projects;
